// 购买企业服务
// mxy
<template>
  <div class="main flex-column" :style="{ minHeight: minHeight + 'px' }">
    <div class="pageHead">
      <span>购买企业服务</span>
    </div>
    <span class="back" @click="back"></span>
    <div class="set_meal">
      <a-spin :spinning="spinning">
        <a-row>
          <a-col v-for="item of attributeList" :span="7" :key="item.id">
            <div class="item">
              <a-card
                hoverable
                style="cursor: pointer"
                :class="orderInfo.id === item.id ? 'item_selected' : ''"
                @click="selectService(item)"
              >
                <h3 class="name">
                  <span
                    class="name ellipsis"
                    :title="productBase.productName + item.typeName"
                    >{{ productBase.productName }}（{{ item.typeName }}）</span
                  >
                </h3>
                <h1
                  :class="orderInfo.id === item.id ? ' priceColor ' : 'price'"
                >
                  {{ item.price }}元/件
                </h1>
                <div class="divider"></div>
                <p class="subtitle">豪华体验</p>
              </a-card>
            </div>
          </a-col>
        </a-row>
      </a-spin>
    </div>
    <div>
      <page-head title="订单信息" />
      <div class="user">
        <a-row>
          <a-col
            class="ellipsis"
            style="height: auto"
            :span="12"
            :title="nameData.name"
          >
            <span>企业购买人：</span><span>{{ nameData.name }}</span>
          </a-col>
          <a-col class="ellipsis" :span="12" :title="obj.enterpriseName">
            <span>企业名称：</span><span>{{ obj.enterpriseName }}</span>
          </a-col>
        </a-row>
        <a-row style="margin-top: 20px">
          <a-col :span="12">
            <span>电话号码：{{ nameData.phoneNumber }}</span>
          </a-col>
          <a-col :span="12">
            <span>总数量：{{ infor.countNumber }}次</span>
          </a-col>
        </a-row>
        <a-row style="margin-top: 20px">
          <a-col :span="12">
            <span>购买数量：</span>
            <a-input-number
              v-model:value="infor.number"
              :min="0"
              :max="1"
              disabled
              @change="generateQRCode"
            >
            </a-input-number>
            <!-- <a-input-number
              v-model:value="infor.number"
              size="mini"
              :min="0"
              :max="1"
              :disabled="(count = 1)"
              @change="generateQRCode"
            >
            </a-input-number> -->
          </a-col>
          <a-col :span="12">
            <span>应付金额：</span>
            <span class="count-price">{{ infor.countPrice }}元</span>
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="footer">
      <a-button
        type="primary"
        size="large"
        :disabled="disabled"
        :loading="loginBtn"
        @click="submit"
      >
        确认订单
      </a-button>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import api from '@/api/API'
import { minheight } from '../components/sameVariable'
import PageHead from '@/components/PageHead'

export default defineComponent({
  components: {
    'page-head': PageHead,
  },
  data () {
    return {
      loginBtn: false,
      disabled: true,
      spinning: false, // 是否加载
      attributeList: [],
      orderInfo: {},
      infor: {
        number: 0,
        price: 0,
        count: 0,
        countNumber: 0,
        countPrice: 0,
        typeName: '',
        id: 0,
        guid: '',
        productCode: '',
      },
      productBase: {},
      orderInforData: {},
      obj: {},
      nameData: {},

    }
  },
  // 页面加载事件
  created () {
    this.minHeight = minheight
    const tenantList = JSON.parse(localStorage.getItem('tenantList'))
    this.obj = tenantList
  },
  mounted () {
    this.getProductAttribute()
    this.getProductBase()
    this.name()
  },
  // 方法
  methods: {
    name () {
      this.nameData = JSON.parse(localStorage.getItem('userInfo'))
    },
    // 计算总金额和总数量
    generateQRCode (value) {
      this.number = value
      this.infor.countPrice = this.number * this.orderInfo.price
      this.infor.countNumber = this.number * this.orderInfo.single
    },
    // 返回上一步
    back () {
      this.$router.go(-1)
    },
    // 获取企业服务信息
    getProductAttribute () {
      this.spinning = true
      api
        .get('/api/app/method/product-attribute', {
          params: { productCode: this.$route.query.name },
        })
        .then(({ data }) => {
          this.spinning = false
          this.attributeList = data
          data[0].selected = true
        })
        .catch(err => {
          this.spinning = false
          console.log(err)
          // this.$message.error('获取企业服务信息失败' + err)
        })
    },
    // 获取企业服务详情信息
    getProductBase () {
      api
        .get('/api/app/method/product-base-info', {
          params: { productCode: this.$route.query.name },
        })
        .then(({ data }) => {
          this.productBase = data
        })
        .catch(err => {
          this.spinning = false
          console.log(err)
          // this.$message.error(err)
        })
    },
    // 选择套餐
    selectService (item) {
      this.disabled = false
      this.orderInfo = item
      this.infor.countPrice = this.orderInfo.price
      this.infor.countNumber = this.orderInfo.single
      this.infor.productCode = this.orderInfo.productCode
      this.infor.id = this.orderInfo.id
      this.infor.guid = this.orderInfo.guid
      this.infor.number = 1
      // this.infor.countPrice = this.orderInfo.single * this.orderInfo.price
    },
    // 生成订单
    generateOrder () {
      const _this = this
      const obj = {
        // orderName: this.productBase.productName, // 订单名称
        // orderDetails: this.productBase.describe, // 订单描述
        orderType: 0, // 订单类型（0 购买，1扣费，2兑换）
        commoditType: 1, // 商品类型 （0版本，1服务，2 其他）
        attrId: this.infor.id,
        attrGuid: this.infor.guid,
        productCode: this.infor.productCode,
        sourceCode: this.$route.query.serviceCode, // 来源于编号 版本编号，服务编号
        sourceId: this.$route.query.serviceID, // 来源于编号 版本编号，服务编号
        // singlePrice: price, // 单价
        // singleNumber: this.infor.countNumber, // 单件数量
        orderNumber: this.number, // 订单数量
        orderUnit: '件', // 订单单位
        orderState: 0, // 订单状态 （是否支付）
        invoiceState: 0, // 发票状态 （0发票状态，1已提交，2已开票）
      }
      api
        .post('/api/app/sys-ent-order-info/sys-ent-order-info', obj)
        .then(({ data }) => {
          _this.orderInforData = data
          if (_this.orderInforData.isNewOrder === false) {
            this.$confirm({
              title: '提示',
              okText: '确定',
              cancelText: '取消',
              content: <div style="color:red;">您有未支付的订单，请先支付</div>,
              onOk () {
                _this.router()
              },
              onCancel () {
                _this.disabled = false
                _this.loginBtn = false
              },
              class: 'test',
            })
            return false
          } else {
            _this.router()
          }
        })
        .catch(err => {
          this.spinning = false
          this.loginBtn = false
          console.log(err)
          // this.$message.error(err)
        })
    },
    // 跳转页面
    router () {
      this.$router.push({
        path: '/EnterpriseManagement/Payment',
        query: {
          orderCode: this.orderInforData.orderCode,
        },
      })
    },
    // 确认订单
    submit () {
      this.disabled = true
      this.loginBtn = true
      this.generateOrder()
    },
  },
})
</script>

<style  lang="scss" scoped>
@import "@/assets/common.scss";

.main {
  position: relative;
  background-color: #fff;
  .pageHead {
    width: 100%;
    height: 60px;
    font-size: 18px;
    line-height: 60px;
    padding: 0 40px;
    border-bottom: 1px solid #f5f5f5;
    position: relative;
  }

  .back:after {
    content: " ";
    cursor: pointer;
    display: inline-block;
    height: 10px;
    position: absolute;
    width: 10px;
    border-width: 0 0 2px 2px;
    border-style: solid;
    -webkit-transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
    transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
    top: 26px;
    left: 15px;
  }
  .item {
    margin: 20px 10px 10px;
    padding: 10px;
    .name {
      margin: 0;
      width: 100%;
      margin-bottom: 10px;
      display: inline-block;
    }

    .price {
      font-weight: bold;
      font-size: 18px;
    }
    .priceColor {
      color: #ffb026;
      font-weight: bold;
      font-size: 18px;
    }
    .subtitle {
      margin: 10px 0 0;
      margin-top: 10px;
      margin-bottom: 0;
      font-weight: normal;
      font-size: 12px;
      color: #a3a3a3;
    }
    .divider {
      height: 1px;
      background-color: #e0e0e0;
    }
  }
  .item_selected {
    border: 1px solid #ffb026;
  }
  .user {
    padding: 20px 40px;

    .count-price {
      font-size: 16px;
      color: red;
      font-weight: 700;
    }
  }
  .footer {
    // position: absolute;
    // right: 17%;
    // top: 70%;
    padding: 30px 0px 40px 648px;
  }
  // .ant-spin-nested-loading {
  //   top:70%;
  //   margin-left: -120px;
  // }
}
</style>
